* {
    box-sizing: border-box;
}

body {
    margin: 0;
    background-color: black;
    color: white;
}

.nav {
    padding: 0;
    margin: 0;
    background-color: white;
    color: black;
    display: flex;
}
.nav ul {
    display: flex;
    list-style: none;
    gap: 1rem;
    padding: 1rem;
    margin: 0;
}

.nav a {
    color: inherit;
    text-decoration: none;
}

Table {
    color: white;
}

Table thead tr td {
    font-weight: bold;
    font-size: 1.75rem;
    text-align: center;
}

Table tbody {
    vertical-align: middle;
    text-align: center;
}

Table tbody td {
    vertical-align: middle;
    text-align: center; 
    font-weight: bold;  
}

.table-hover tbody tr:hover td {
    background: rgb(95, 96, 97);
    color: white;
}

input {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
}

label {
    font-weight: bold;
}

h3 {
    font-weight: bold;
    margin-top: 2rem;
    text-align: center;
}

#sell {
    background-color: black;
    color: black;
    border: none;
    box-shadow: none;
    outline: none;
}

Table thead{
    position: sticky;
    top: 0px;
    background-color: black;
}